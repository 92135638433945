.sensor-select {
    display: inline-block;
    border: 1px solid black;
    background: rgba(7,84,140,0.5);
    padding: 4px;
    margin: 4px;
    color: white;
    text-align: center;
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
}

.sensor-select.selected {
  background: rgba(7,84,140,0.9);
  cursor: auto;
}

.rv-xy-plot {
  margin: auto;
}

label {
  display: inline-block;
  margin-left: 5px;
}

#combine-loader {
  position: fixed;
  width: 250px;
  height: 230px;
  border: 1px rgba(7,84,140,0.9) solid;
  top: 50%;
  left: 50%;
  text-align: center;
  padding: 15px;
  font-weight: bold;
  background: white;
  transform: translate(-50%, -50%);
  z-index: 3;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
}

.disabled {
  pointer-events: none;
}

#vayla-button {
  float: right;
}

.sensor-location-select {
  width: 200px;
  font-size: 14px;
}

.rv-xy-plot__axis__tick__text {
  font-size: 10px !important;
}
