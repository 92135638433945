body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

h1 {
  text-align: center;
  margin-top: 1.5em;
}

th {
  text-align: center;
}

td {
  text-align: center;
  padding: 0;
}

table { 
  border-spacing: 0;
  border-collapse: separate;
}

table tr:nth-child(2n) {
  background-color: rgba(7,84,140,0.05);
}

.radio {
  display: inline;
}

input[type=radio],
input.radio {
  margin-right: 30px;
}

.container {
  max-width: 1200px;
  margin-bottom: 20px;
}


input[type=submit], button[type=submit] {
  margin-right: 10px;
}

button:hover {
  background-color: rgb(0, 55, 94) !important;
  border-color: rgb(0, 55, 94) !important;
  color: white !important;
}

.page {
  margin-top: 54px;
}

.red {
    background-color: red;
}

.red-text {
    color: red !important;
}

.yellow {
    background-color: yellow !important;
    color: black !important;
}

.green {
    background-color: green;
}

.array-item-list {
  flex-direction: column;
}

.array-item {
  margin: 10px;
  max-width: 175px;
}

.btn-info {
  width: 175px;
  margin-left: 10px;
}

.btn-add > i {
  display: none;
}

.btn-add::after {
  content: "+";
  font-weight: bold;
  font-size: 25px;
}

.array-item-remove > i {
  display: none;
}

.array-item-remove::after {
  content: "-";
  font-weight: bold;
  font-size: 25px;
}

.col_xs-9 {
  float: left;
}

.col_xs-3 {
  float: right;
}

.datafield {
  margin-bottom: 10px;
}

.leaflet-control-zoom  {
    display: none;
}

#map-area {
    position: relative;
    margin-top: -9px;
    width: 100%;
    height: 100%;
    z-index: 0
}

.state {
  display: inline-block;
  border: 1px solid black;
  background: rgba(7,84,140,0.5);
  padding: 4px;
  color: white;
  width: 120px;
  text-align: center;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.state.selected {
  background: rgba(7,84,140,0.9);
  cursor: auto;
}

.pswp__zoom-wrap {
  will-change: auto !important;
  -webkit-backface-visibility: visible !important;
}

.button-primary {
  margin-right: 15px;
}

.main.loader {
  position: fixed;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  width: 100px;
  height: 100px;
  border-width: 15px;
}

.leaflet-popup-content span {
  font-size: 12px;
}

.info {
  font-size: 13px;
  margin-left: 10px;
  color: white;
  background: #005490;
  border-radius: 100%;
  width: 21px;
  height: 21px;
  text-align: center;
  display: inline-block;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
}
