fieldset {
    border: 1px solid rgba(7,84,140,0.9);
    border-radius: 5px;
    padding: 5px;
}

span {
    font-size: 15px;
}

.center {
  text-align: center;
}

.wide-area {
    margin: auto;
    width: 90%;
}
  
#load-data-modal {
    position: fixed;
    width: 75%;
    height: 75%;
    border: 1px rgba(7,84,140,0.9) solid;
    top: 50%;
    left: 50%;
    text-align: center;
    font-size: 20px;
    padding: 15px;
    font-weight: bold;
    background: white;
    transform: translate(-50%, -50%);
    z-index: 3;
    overflow: scroll;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    font-size: 10px;
}

hr {
    border: 0;
    height: 2px;
    background: rgba(7,84,140,0.9);
    background-image: linear-gradient(to right, white, rgba(7,84,140,0.9), white); 
}

#crosshair-data {
    border-radius: 5px;
    background: rgba(7,84,140,0.9);
    color: white;
    font-size: 14px;
    font-weight: bold;
    width: 140px;
    padding: 2px;
}
